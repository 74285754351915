<template>
  <div id="app"  :style="'transform:scale(' + scaleChange + ',' + scaleChange + ')'">
    <LoginView v-if="!loginToken"></LoginView>
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import LoginView from "@/components/loginModal/index.vue";

export default {
  name: 'App',
  components: { LoginView },
  data() {
    return {
      isRouterAlive: true,
      scaleChange: 1,
      loading: false,
      loginToken: this.$store.state.token,
    };
  },
  watch: {
    "$store.state.token": {
      handler: function (newVal, oldVal) {
          // 需要刷新整个页面
          this.isRouterAlive = false;
          this.$nextTick(function () {
            this.isRouterAlive = true;
          });
        this.loginToken = newVal;
      },
    },
  },
  mounted() {
    this.scaleChange = document.body.clientWidth / 1920;
    window.addEventListener("resize", () => {
      this.scaleChange = document.body.clientWidth / 1920;
    });
  },
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: fixed;
  width: 1920px;
  height: 1080px;
  transform-origin: top left;
  z-index: 300;
  
  background: url("./assets/images/bg.png") no-repeat left top;
    background-size: 100% 100%;
    padding: 0 18px;
}
.ivu-spin-fix{
  background: rgba(255,255,255,0.1) !important;
}
</style>
