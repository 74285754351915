<template>
    <div style="position: relative;">
        <header>
            <div class="sub-title">
                <div class="sub-text">基础数据统计</div>
                <p class="sub-text-en">Agricultural data statistics</p>
            </div>
        </header>
        <ul class="item-box">
            <li
                v-for="(item, index) in farmingData"
                :class="item.classname"
                :key="index"
            >
                <div class="item-title">
                    {{ item.title }}<span class="item-line">|</span
                    ><span class="item-unit">{{ item.unit }}</span>
                </div>
                <div class="item-num">
                    <countTo
                        :startVal="0"
                        :endVal="parseFloat(item.value)"
                        :duration="4000"
                    ></countTo>
                </div>
            </li>
        </ul>
        <Spin size="large" fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { baseApi } from "@/api";
export default {
    name: "farming",
    components: {},
    props: {},
    data() {
        return {
            loading:false,
            farmingData: [
                {
                    title: "覆盖学校",
                    unit: "所",
                    value: 0,
                },
                {
                    title: "配送企业",
                    unit: "家",
                    value: 0,
                },
                {
                    title: "覆盖人数",
                    unit: "人",
                    value: 0,
                },
                {
                    title: "营改学校",
                    unit: "所",
                    value: 0,
                },
                {
                    title: "营改人数",
                    unit: "人",
                    value: 0,
                },
                {
                    title: "国补采购金额",
                    unit: "元",
                    value: 0,
                },
            ],
        };
    },
    mounted() {
        this.initData();
    },
    created() {
        this.init();
    },
    methods: {
        async initData() {
            try {
                this.loading = true;
                const staticData={}
                if(this.$route.query.city_id ){
                    staticData.city_id =this.$route.query.city_id 
                }
                if(this.$route.query.area_id){
                    staticData.area_id=this.$route.query.area_id
                }
                const resData = await baseApi(staticData);
               const obj=resData.data
               this.farmingData[0].value=obj.school_cnt
               this.farmingData[1].value=obj.merchant
               this.farmingData[2].value=obj.person_num
               this.farmingData[3].value=obj.yyc_school_cnt
               this.farmingData[4].value=obj.yyc_student_num
               this.farmingData[5].value=obj.yyc_total_amt
          
            } finally {
                this.loading = false;
            }
        },
        init() {
            //                console.log(this.$route.query.id)
            if (this.$route.query.id === undefined) {
                console.log("主页");
            } else {
                console.log("副页");
            }
            this.farmingData.map(function (currentValue, index, arr) {
                if (!parseInt((index + 1) % 3)) {
                    currentValue.classname = "item";
                } else {
                    currentValue.classname = "item item-border";
                }
            });
            //                console.log(this.farmingData)
        },
    },
  
};
</script>
<style type="text/css"></style>
