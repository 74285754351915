<template>
    <div class="clear-float" style="margin: 10px 0">
        <header style="position: relative">
            <div class="sub-title">
                <div class="sub-text"><span>各分类交易统计占比图(万元)</span>
                    <span style="margin-left: 60px;">
                        <select v-model="dateTye" @change="initData">
                            <option  value="0">全部</option>
                            <option  value="1">本日</option>
                            <option  value="2">本月</option>
                            <option  value="3">本年</option>
                        </select>
                    </span>
                </div>
                <p class="sub-text-en">
                    Statistical proportion chart of transactions by category
                </p>
            </div>
        </header>
        <Row>
            <Col span="24">
                <div class="clear-float" style="position: relative">
                    <v-chart
                        style="width: 100%; height: 250px"
                        :options="classOptions"
                    ></v-chart>
                    <div v-if="!loading">
                        <img class="bg-charts" :src="yuan" />
                        <span class="label-address">各分类</span>
                    </div>

                    <Spin size="large" fix v-if="loading"></Spin>
                </div>
            </Col>
        </Row>
    </div>
</template>
<script>
import { cateApi } from "@/api";
import yuan from "@/assets/images/yuan.svg";
export default {
    name: "turnover",
    components: {},
    props: {},
    data() {
        return {
            yuan,
            classOptions: {},
            quXianOptions: {},
            turnoverOptions: [
                { name: "", Options: {} },
                { name: "", Options: {} },
            ],

            isActive: 0,
            dateTye:0,
        };
    },
    created() {
        this.initData();
    },
    methods: {
        setClassOptionsOptionsData(list) {
            const colorList = [
                {
                    start: "#52FFF9",
                    end: "#54FFB2",
                },
                {
                    start: "#F1910E",
                    end: "#FCC700",
                },
                {
                    start: "#D2F330",
                    end: "#53CC79",
                },
                {
                    start: "#26C5FF",
                    end: "#167EDB",
                },
                {
                    start: "#741b44",
                    end: "#167EDB",
                },
                {
                    start: "#915c74",
                    end: "#cf0808",
                },
                {
                    start: "#a51457",
                    end: "#3f502c",
                },
                {
                    start: "#25ddff",
                    end: "#02970d",
                },

            ];

            const seriesList = list.map((item, index) => {
                let colorDefault = {
                    start: "#26C5FF",
                    end: "#167EDB",
                };
                if (colorList[index]) {
                    colorDefault = colorList[index];
                }
                return {
                    name: item.cate_name,
                    value: item.shipping_money,
                    itemStyle: {
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: colorDefault.start,
                                },
                                {
                                    offset: 1,
                                    color: colorDefault.end,
                                },
                            ],
                            global: false,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: "center",
                        },
                    },
                };
            });
            this.classOptions = {
                legend: {
                    x: "left",
                    orient:'vertical',
                    itemWidth: 10,
                    itemHeight: 10,
                    top: 20,
                    textStyle: {
                        color: "#9590C5",
                    },
                    data: list.map((item) => item.cate_name),
                },
                grid: {
                    left: 20,
                    top: 60,
                    bottom: 10,
                },
                tooltip: {
                    formatter:
                        '<div style="padding: 3px"><div style="text-align: left;margin: 3px auto;"></div>{b}:{c} ({d}%)</div>',
                },
                series: {
                    data: seriesList,
                    type: "pie",
                    smooth: true,
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false,
                    },
                    radius: ["65%", "75%"],
                    center: ["75%", "62%"],
                },
            };
        },
        async initData() {
            try {
                this.loading = true;
                const staticData = {};
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                staticData.dateTye = this.dateTye;
                const resData = await cateApi(staticData);
                this.setClassOptionsOptionsData(resData.data||[]);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped>
select {
    background-color: #275acd;
}
select option {
    background-color: cadetblue;
}
select option:checked {
    background-color: cadetblue;
}
.bg-charts {
width: 131px;
position: absolute;
left: 239px;
top: 90px;
z-index: 1;
}
.label-address {
    position: absolute;
    display: block;
    left: 285px;
    top: 141px;
    color: #d9dbe4;
}
</style>
