
<template>
  <div>
    <Table class="inListTable" :columns="inListTitle" :data="live">
      <template #expand="{ row, index }">
        <div>{{row.qrurl}}</div>
      </template>
      <template #file_name="{ row, index }">
        <img :src="row.file_name" fit="contain" width="30px" height="30px" @click="handelShowPic(row.file_name)" />
      </template>
    </Table>

    <Modal
        v-model="showPic"
        footer-hide
    >
      <div style="text-align: center;">
        <img preview style="max-height:600px;max-width: 100%;min-width: 100px;" :src="picUrl"></img>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  props: ["live"],
  data() {
    return{
      showPic: false,
      picUrl: '',
      inListTitle: [
        {
          title: "时间",
          key: "create_time",
          width: 180
        },
        {
          title: "出入库地",
          key: "scene_name",
          width: 180
        },
        {
          title: "图片",
          slot: "file_name",
          width: 100
        },
        {
          title: "地址",
          key: "filming_add",
        },
      ]
    }
  },
  created() {
    console.log("live---",this.live);
  },
  methods: {
    handelShowPic(url){
      this.showPic=true
      this.picUrl=url
    },
  }
}
</script>

<style scoped>

</style>