<template>
    <div>
        <header>
            <div class="sub-title" style="position: relative">
                <div class="sub-text">
                    <span>各供应商供应情况统计</span>
                    <span style="margin-left: 60px;">
                        <select v-model="dateTye" @change="initData">
                            <option  value="0">全部</option>
                            <option  value="1">本日</option>
                            <option  value="2">本月</option>
                            <option  value="3">本年</option>
                        </select>
                    </span>
                </div>
                <p class="sub-text-en">
                    Statistics on the supply situation of each supplier
                </p>
                <button class="more-button" @click="modal=true">More>></button>
            </div>
        </header>
        <div style="position: relative">
            <div class="problem-header clear-float">
                <span class="problem-item">序号</span>
                <span class="problem-item">供货商</span>
                <span class="problem-item">笔数</span>
                <span class="problem-item">发货(万元)</span>
                <span class="problem-item">收货(万元)</span>
            </div>
            <Spin size="large" fix v-if="loading"></Spin>
            <vue-seamless-scroll
                :class-option="option"
                :data="infoData"
                class="seamless-warp"
            >
                <ul>
                    <li
                        class="clear-float"
                        v-for="(item, index) in infoData"
                        :key="item.index"
                    >
                        <span class="problem-item"
                            ><span class="radius">{{ index + 1 }}</span></span
                        >
                        <span class="problem-item">{{
                            item.merchant_name
                        }}</span>
                        <span class="problem-item">{{ item.order_count }}</span>
                        <span class="problem-item">{{
                            item.shipping_money
                        }}</span>
                        <span class="problem-item">{{ item.reciver_money }}</span>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>



        <Modal
            v-model="modal"
            title="各供应商供应情况统计明细"
            footer-hide
            width="1080px"
        >
            <div style="position: relative; text-align: center; height: 580px">
                <Spin size="large" fix v-if="loadingModal"></Spin>
                <LookMore
                    :seamlesstitle="modelTableTitle"
                    :seamlessbody="modelList"
                    :pagination="modelPagination"
                    @change="handelModelChange"
                ></LookMore>
            </div>
        </Modal>
    </div>
</template>
<script>
import LookMore from "@/components/lookMore/index.vue";
import { merchantApi } from "@/api";

export default {
    name: "statistics",
    components: {
        LookMore
    },
    props: {},
    data() {
        return {
            loading: false,
            infoData: [],
            modal:false,
            modelTableTitle: [
                {
                    title: "供货商",
                    styles: { width: "25%" },
                    textcolor: "",
                    key: "merchant_name",
                },
                {
                    title: "笔数",
                    styles: { width: "25%" },
                    textcolor: "#1E97DB",
                    key: "order_count",
                },
                {
                    title: "发货(万元)",
                    styles: { width: "25%" },
                    textcolor: "",
                    key: "shipping_money",
                },
                {
                    title: "收货(万元)",
                    styles: { width: "25%" },
                    textcolor: "#1E97DB",
                    key: "reciver_money",
                },
               
            ],
            modelPagination:{
                total: 0,
                current: 1,
                page_size: 10,
            },
            modelList:[],
            loadingModal:false,
            dateTye:0,
        };
    },
    computed: {
        option() {
            return {
                step: 0.1,
            };
        },
    },
    mounted() {
        this.init();
        this.initData();
        this.getDataList()
    },
    methods: {
        handelModelChange(e){
            this.modelPagination.current = e;
            this.getDataList();
        },
        async getDataList() {
            try {
                this.loadingModal = true;
                const staticData = {
                    page: this.modelPagination.current,
                    limit: 10,
                };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await merchantApi(staticData);
                this.modelPagination.total = Number(resData.count);
                this.modelList = resData.data||[];
            } finally {
                this.loadingModal = false;
            }
        },
        async initData() {
            try {
                this.loading = true;
                const staticData = {
                    page:1,
                    limit:50
                };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                staticData.dateTye = this.dateTye;
                const resData = await merchantApi(staticData);
                console.log(resData.data);
                this.infoData = resData.data;
            } finally {
                this.loading = false;
            }
        },
        init() {
            this.infoData.map(function (currentValue, index, arr) {
                (currentValue.distributor = currentValue.distributor + "家"),
                    (currentValue.cooperative =
                        currentValue.cooperative + "家"),
                    (currentValue.farmer = currentValue.farmer + "户");
            });
        },
    },
};
</script>
<style type="text/css">
    select {
        background-color: #275acd;
    }
    select option {
        background-color: cadetblue;
    }
    select option:checked {
        background-color: cadetblue;
    }
</style>
