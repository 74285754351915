import axios from "axios"; // 引入axios
import { Message } from "iview";
import store from '@/store'
let in401num = 0;

const service = axios.create({
    baseURL: "https://api.houqinban.com",
    // timeout: 10000,
});

// http request 拦截器
service.interceptors.request.use(
    (config) => {
        if(config.url!='/datav/login'){
            config.headers = {
               'Authorization': 'Bearer '+localStorage.getItem("data_token") || "",
                ...config.headers,
            };
        }
        return config;
    },
    (error) => {
        return error;
    }
);

// http response 拦截器
service.interceptors.response.use(
    (response) => {
        if (response.data.code == 0) {
            return Promise.resolve(response.data);
        } else {
            if (response.data.code == 401) {
                if(in401num === 0){
                    in401num = 1;
                    // 需要显示登录页面
                    store.commit("CLEAR_TOKEN");
                    setTimeout(() => {
                        in401num = 0;
                    },1000)
                }
                return Promise.reject(response.data);
            }
            Message.error(response.data.message)
            return Promise.reject(response.data);
        }
    },
    (error) => {
        const errorRes = error.response.data;
        return Promise.reject(errorRes);
    }
);
export default service;
