
<template>
<div class="inListDetail">
  <Modal v-model="show" title="入库订单详情" footer-hide width="70%">
    <Table class="inListTable" :columns="inListTitle" :data="inListData" height="600">
      <template #qrurl="{ row, index }">
        <img :src="row.qrurl" fit="contain" width="30px" height="30px" @click="handelShowPic(row.qrurl)" />
      </template>
    </Table>

    <Spin size="large" fix v-if="loading"></Spin>
  </Modal>

  <Modal
      v-model="showPic"
      footer-hide
  >
    <div style="text-align: center;">
      <img preview style="max-height:600px;max-width: 100%;min-width: 100px;" :src="picUrl"></img>
    </div>
  </Modal>

</div>
</template>

<script>
import {
  inDetailApi,
  outDetailApi,
} from "@/api";
import LookMore from "@/components/lookMore/index.vue";
import expandTable from "./expandTable.vue";
import { resolveComponent } from 'vue';
export default {
  components: {
    LookMore,
  },
  data() {
    return {
      loading: false,
      showPic: false,
      picUrl: false,
      show: false,
      record: {},
      inListData: [],
      inListTitle: [
        {
          type: 'expand',
          width: 50,
          render: (h, { row }) => {
            console.log(row);
             let liveArr = row.live;
             return h(expandTable, {props: {live: liveArr}})
          }
        },
        {
          title: "分类名称",
          key: "cate_name",
        },
        {
          title: "商品名称",
          key: "product_name",
        },
        {
          title: "采购数量",
          key: "purchase_num",
        },
        {
          title: "分拣数量",
          key: "sorting_sum",
        },
        {
          title: "收货数量",
          key: "num",
        },
        {
          title: "收货金额",
          key: "get_money",
        },
        {
          title: "溯源码",
          slot: "qrurl",
        },
      ]
    }
  },
  methods: {
    handelShowPic(url){
      this.showPic=true
      this.picUrl=url
    },
    ModalShow(obj){
      this.inListData = [];
      this.record = obj.record;
      this.loading = obj.show;
      this.show = obj.show;
      this.getDetail(obj.record);
    },
    async getDetail(record){
      try {
        let res = await inDetailApi({order_id: record.order_id});
        console.log(res);
        this.inListData = res.data;
        this.loading = false;
      }catch (e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">

.inListTable ::v-deep td.ivu-table-expanded-cell{
  padding: 10px 40px !important;
}
.inListTable ::v-deep .ivu-table th{
  background-color: transparent !important;
}


</style>