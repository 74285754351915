<template>
    <div>
        <header>
            <h1 class="title">{{cityName}}中小学校“数字食堂”监管平台<p>食材管理</p></h1>
        </header>
        <div class="content-box">
            <aside class="left">
                <farming></farming>
                <food></food>
                <tradingCurve></tradingCurve>
            </aside>
            <aside class="right">
                <CountryTrade></CountryTrade>
                <turnover></turnover>
                <statistics></statistics>
            </aside>
            <section>
                <div class="total-box">
                    <total></total>
                </div>
                <div class="map-svg">
                    <areaMap :areaId="areaCode" @change="handelClick"></areaMap>
                </div>
                <div class="bottom">
                    <bottomTotal></bottomTotal>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import farming from "@/components/farming";
import food from "@/components/food";
import tradingCurve from "@/components/tradingCurve";
import turnover from "@/components/turnover";
import statistics from "@/components/statistics";
import total from "@/components/total";
import bottomTotal from "@/components/bottomTotal";
import areaMap from "@/components/areaMap";
import CountryTrade from "@/components/countryTrade/index.vue";
export default {
    name: "provincial",
    components: {
        farming,
        food,
        tradingCurve,
        turnover,
        statistics,
        total,
        bottomTotal,
        areaMap,
        CountryTrade,
    },
    data() {
        return {
            areaCode: this.$route.query.areaCode,
            cityName: this.queryCity(this.$route.query.city_id),
        };
    },
    created(){

    },
    mounted(){
    },
    methods: {
        queryCity(city_id) {
            if(city_id === '420500'){
                return '宜昌市';
            }else  if(city_id === '420300'){
                return '十堰市';
            }else  if(city_id === '420100'){
                return '武汉市';
            }else  if(city_id === '420600'){
                return '襄阳市';
            }else  if(city_id === '420700'){
                return '鄂州市';
            }else  if(city_id === '420800'){
                return '荆门市';
            }else  if(city_id === '420900'){
                return '孝感市';
            }else  if(city_id === '421000'){
                return '荆州市';
            }else  if(city_id === '421100'){
                return '黄冈市';
            }else  if(city_id === '421200'){
                return '咸宁市';
            }else  if(city_id === '421300'){
                return '随州市';
            }else  if(city_id === '422800'){
                return '恩施土家族苗族自治州';
            }else  if(city_id === '420200'){
                return '黄石市';
            }else  if(city_id === '429005'){
                return '潜江市';
            }else  if(city_id === '429006'){
                return '天门市';
            }else  if(city_id === '429004'){
                return '仙桃市';
            }else  if(city_id === '429021'){
                return '神农架林区';
            }

        },
        handelClick({ adCode, keyId }) {
            this.$router.push({
                path: "/countyView",
                query: {
                    parentCode: this.$route.query.areaCode,
                    areaCode: adCode,
                    area_id: keyId,
                },
            });
        },
    },
};
</script>
<style type="text/css"></style>
