<template>
  <div>
      <header>
          <h1 class="title">{{areaName}}中小学校“数字食堂”监管平台<p>食材管理</p></h1>
      </header>
      <div class="content-box">
          <aside class="left">
              <farming></farming>
              <food></food>
              <tradingCurve></tradingCurve>
          </aside>
          <aside class="right">
              <outAndIn></outAndIn>
              <turnover></turnover>
              <statistics></statistics>
          </aside>
          <section>
              <div class="total-box">
                  <total></total>
              </div>
              <div class="map-svg" style="width: 94%!important; margin-left: 25px!important;">
                  <countymap></countymap>
              </div>
              <div class="bottom">
                  <bottomTotal></bottomTotal>
              </div>
          </section>
      </div>
  </div>
</template>
<script>
import axios from "axios";
import farming from "@/components/farming";
import food from "@/components/food";
import tradingCurve from "@/components/tradingCurve";
import turnover from "@/components/turnover";
import statistics from "@/components/statistics";
import total from "@/components/total";
import bottomTotal from "@/components/bottomTotal";
import countymap from "@/components/countymap";
import outAndIn from "@/components/outAndIn/index.vue";
export default {
  name: "provincial",
  components: {
      farming,
      food,
      tradingCurve,
      turnover,
      statistics,
      total,
      bottomTotal,
      countymap,
      outAndIn,
  },
  data() {
      return {
         parentCode: this.$route.query.parentCode,
          areaCode: this.$route.query.areaCode,
          areaName:""
      };
  },
  created(){
      this.initData()
  },
  methods: {
    initData() {
            axios
                .get(
                    `/hbJson/${this.parentCode}.geoJson`
                )
                .then((response) => {
                    const features=response.data.features
                    console.log(features)
                    const findObj = features.find(
                    (item) => item.properties.adcode === Number(this.areaCode)
                );
                if(findObj){
                    this.areaName=findObj.properties.name
                }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
  },
};
</script>
<style type="text/css"></style>
